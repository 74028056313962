import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Layout from "../components/layout"
import { Container, Jumbotron, Button } from 'reactstrap'
import { navigate, Link } from '@reach/router';


const success = () => {
    return (
        <Layout>

            <Container
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}
                >
                <Jumbotron >
                    <h2 className="display-4">
                        Success
                    </h2>
                    <p className="lead">
                        Your email has been sent!
                    </p>
                    <hr className="my-2" />
                    <p className="lead">
                        Check out the latest updates on the NETS API <Link to="/docs/overview/">here</Link>
                    </p>
                    <p className="lead">
                        <Button
                            color="primary"
                            onClick={event => {
                                event.preventDefault()
                                navigate('/')
                            }}
                        >
                            Go back home
                        </Button>
                    </p>

                </Jumbotron>
            </Container>
        </Layout>
    )
}

export default success
